<style scoped>
.btnGreenClr {background: #00b894;}
.btnRedClr {background: #d63031}
.redclr{background: #d63031;}
.greenclr{background: #00b894;}
.dayhighClr{background: #00b894;}
.lowClr{background: #d63031;}
.greycolor{background: grey;}
/* .negativevalue{color: #00b894;}
.positivevalue{color: #d63031;} */
.lineheight{line-height: 18px !important;}
.sentimentBtn{min-width: 65px;}
.dayBreakBtn{min-width: 120px;}
/* .analysis-table button{min-width: 118px;font-weight: normal;border-radius: 5px;font-size: 12px;padding: 2px 6px 4px 6px;position: relative;} */
 /* .sentiment .sentimentbtn{min-width: 60px;font-weight: normal;border-radius: 5px;font-size: 12px;padding: 2px 6px 4px 6px;position: relative;}  */
.analysis-table .arrow {top: -6px; font-size: 22px!important; right: 5px; padding: 0px 12px 0px 12px; vertical-align: text-top; border-radius: 4px;}
/* .analysis-table-align .arrow{top: -6px; font-size: 22px!important; right: 5px; padding: 0px 6px 0px 6px; vertical-align: text-top; border-radius: 4px;} */
/* .analysis-table button .arrow2 {top: -6px; font-size: 22px!important; right: 4px; background-color: #00b894; padding: 0px 12px 0px 12px; vertical-align: text-top; border-radius: 4px;} */
.strike{background:#EFEFEF}
.bg-green{background-color: #00b894}
.bg-red{background-color: #d63031}
.bg-violet{background-color: #003473}
.theme--dark .btnBlueClr{background: #1a3f5a;}
.theme--dark .btnGreenClr{background: #1C3126;}
.theme--dark .btnRedClr{background: #371A1A;}
.theme--dark .btnOrangeClr{background: #847700;color: #000000 !important;}
.theme--dark .strike{background:#000000}
.theme--dark .v-data-table th{background: #1e1e1e;}
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}
</style>

<template>
<div>
 <v-flex class="pa-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
               <v-col class="" cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                 
                  <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
              <v-col class="" cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-text-field v-model="selectdate" label="Date" disabled dense hide-details outlined ></v-text-field>
                </div>
              </v-col>
               <v-col class="" cols="6" lg="2" md="3" sm="4" xs="12" >
             <v-select v-model="expirydate" :items="expiryList" dense  outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
                <v-col class="" cols="6" lg="2" md="3" sm="4" xs="12">
                <div class="">
                  <v-select  v-model="timedata" :items="timeinterval" hide-details label="Time Interval"  outlined dense :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
               <v-col class="" cols="6" lg="2" md="3" sm="4" xs="12">
                    <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
    <v-simple-table class="analysis-table analysis-table-align sentiment f-semibold a-box">
      <thead>
        <tr>
          <th class="text-right">Time</th>
          <th class="text-right">Future LTP</th>
          <th class="text-right  lineheight">Chng. In Call OI</th>
          <th class="text-right  lineheight">Chng. In Put OI</th>
          <th class="text-right  lineheight">Diff. in OI</th>
          <th class="text-center lineheight">Direction of Chng</th>
          <th class="text-right  lineheight">Chng. In Direction</th>
          <th class="text-right lineheight">Direction of Chng. %</th>
          <th class="text-right lineheight">Net PCR</th>
          <th class="text-center lineheight">Day High/Low Diff. in OI</th>
          <th class="text-center">Sentiment</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="(item, index) in oiAnalysisTableData" :key="index">
          <td class="text-right">{{ item.time }}</td>
          <td class="text-right py-1">{{ item.ltp }}
            <div class="py-1" :class="item.ltpChange > 0 ? 'positivevalue' : 'negativevalue'">{{item.ltpChange}}</div>
          </td>
          <td class="text-right">{{ item.chngcalloi }}</td>
          <td class="text-right">{{ item.chngputtoi }}</td>
          <td class="text-right">{{ item.diffinoi}}</td>
          <td class="text-center">
              <!-- <span :class="item.Directioninchange == 'up' ? 'bg-green' : item.Directioninchange == 'down' ? 'bg-red' : item.Directioninchange == 'equal' ? 'bg-violet' : ''" class="arrow white--text">
                {{item.Directioninchange == "up" ? "&#8593;" : item.Directioninchange == "down" ? "&#8595;" :  item.Directioninchange == "equal" ? "&#8596;":"" }}</span> -->
          </td>
          <td class="text-right py-1" >{{ item.ltp }}
            <div class="py-1" :class="parseFloat(item.chngindirection) >=0 ? 'positivevalue': 'negativevalue' ">{{ item.chngindirection }}</div>
            </td>
          <td class="text-right"><label :class="parseFloat(item.Directionofchng)  >= 0 ? 'positivevalue': 'negativevalue' ">{{ item.Directionofchng }} %</label></td>
          <td class="text-right">
            {{item.netpcr}}
          </td>
          <td class="text-center">
           <button
              class="white--text dayBreakBtn font-weight-bold caption py-1 px-2 rounded" :class="item.dayhighglow == 'Day High Break' ? 'dayhighClr' : item.dayhighglow == 'Day Low Break' ? 'lowClr' : item.dayhighglow == 'NA' ? '' : '' ">
              {{ item.dayhighglow }}
            </button>
          </td>
          <td class="text-center">
               <button
              class="white--text sentimentBtn font-weight-bold caption py-1 px-2 rounded" :class=" item.sentiment == 'Bearish' ? 'redclr' : item.sentiment == 'Bullish' ? 'greenclr' : '' ">
              {{ item.sentiment }}
            </button>
          </td>

        </tr>
      </tbody>
    </v-simple-table>
          </v-flex>
        </v-layout></v-container>
    </v-flex>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  data() {
    return {
     selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
       instrument: "BANKNIFTY",
      strikedata: "35000",
     timedata: "1MIN",
      timeinterval: ["1MIN"],
      oiAnalysisTableData: [
        { time: "11:49:00", chngcalloi: "1,36,625", chngputtoi: "1,06,375", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up", diffinoi: "2,36,675", putInterpretation: "Short Build Up", Directionofchng: "-42.5", putBreak: "", ltp: "38529.15", chngindirection:"45,325" ,netpcr:"2.32", dayhighglow:"Day High Break", sentiment:"Bearish", Directioninchange:"up"},
        { time: "11:45:00", chngcalloi: "1,97,850", chngputtoi: "1,04,525", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up", diffinoi: "2,37,450", putInterpretation: "Short Build Up", Directionofchng: "36,433.33 ", putBreak: "", ltp: "38512.35", chngindirection:"57,675" ,netpcr:"1.66", dayhighglow:"Day High Break" ,sentiment:"Bearish",Directioninchange:"up"},
        { time: "11:40:00", chngcalloi: "1,94,525", chngputtoi: "76,950", callLtp: "281.95",ltpChange:'27.05',  callInterpretation: "Shorts Covering", diffinoi: "1,92,125	", putInterpretation: "Short Build Up", Directionofchng: "99.63", putBreak: "", ltp: "38535.54",chngindirection:"-30,975" ,netpcr:"7.66", dayhighglow:"Day High Break",sentiment:"Bullish",Directioninchange:"down"},
        { time: "11:35:00", chngcalloi: "1,24,150", chngputtoi: "21,550", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "1,15,000", putInterpretation: "Long Unwinding", Directionofchng: "272.87", putBreak: "", ltp: "38365.50",chngindirection:"-30,275" ,netpcr:"0.83", dayhighglow:"Day Low Break",sentiment:"Bullish",Directioninchange:"up"},
        { time: "12:30:00", chngcalloi: "1,49,475", chngputtoi: "29,325", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "33,050", putInterpretation: "Long Unwinding", Directionofchng: "175.73", putBreak: "", ltp: "38259.85	",chngindirection:"2,575" ,netpcr:"4.59",dayhighglow:"Day High Break",sentiment:"Bearish",Directioninchange:"up"},
        { time: "12:54:00", chngcalloi: "22,775",   chngputtoi: "59,050", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "27,300	", putInterpretation: "Long Unwinding", Directionofchng: "137.48", putBreak: "", ltp: "38266.90	",chngindirection:"57,975" ,netpcr:"1.00",dayhighglow:"Day Low Break",sentiment:"Bearish",Directioninchange:"down"},
        { time: "12:39:00", chngcalloi: "77,175",   chngputtoi: "93,450", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "-35,575", putInterpretation: "Long Unwinding", Directionofchng: "-59.38", putBreak: "", ltp: "38266.99",chngindirection:"2,325" ,netpcr:"1.63",dayhighglow:"Day Low Break",sentiment:"Bearish",Directioninchange:"up"},
        { time: "10:00:00", chngcalloi: "57,375",   chngputtoi: "63,950", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "-78,175", putInterpretation: "Long Unwinding", Directionofchng: "-110.9", putBreak: "", ltp: "38357.30",chngindirection:"-1,30,800",netpcr:"1.18",dayhighglow:"Day High Break",sentiment:"Bullish",Directioninchange:"up"},
        { time: "09:40:00", chngcalloi: "1,07,250", chngputtoi: "77,525", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "-2,975", putInterpretation: "Long Unwinding", Directionofchng: "10.41", putBreak: "", ltp: "38347.20	",chngindirection:"81,975" ,netpcr:"5.88",dayhighglow:"Day High Break",sentiment:"Bearish",Directioninchange:"equal"},
        { time: "09:29:00", chngcalloi: "41,250",   chngputtoi: "96,950", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "81,750", putInterpretation: "Long Unwinding", Directionofchng: "272.87 ", putBreak: "", ltp: "38315.65",chngindirection:"60,900" ,netpcr:"45.90",dayhighglow:"Day High Break",sentiment:"Bearish",Directioninchange:"up"},
        { time: "09:25:00", chngcalloi: "64,625",   chngputtoi: "86,950", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up",   diffinoi: "-12,775", putInterpretation: "Long Unwinding", Directionofchng: "-16.91", putBreak: "", ltp: "38321.39",chngindirection:"-9,950" ,netpcr:"3.89",dayhighglow:"Day Low Break",sentiment:"Bearish",Directioninchange:"up"},
        { time: "09:20:00", chngcalloi: "79,500",   chngputtoi: "1,56,375", callLtp: "281.95",ltpChange:'27.05', callInterpretation: "Long Build Up", diffinoi: "-225", putInterpretation: "Long Unwinding", Directionofchng: "167.91", putBreak: "", ltp: "38466.45",chngindirection:"21,000" ,netpcr:"9.78",dayhighglow:"Day High Break",sentiment:"Bullish",Directioninchange:"down"},
      ],
    };
  },
      methods:{
      getCurrentPrice (value) {
      if(value)
      return this.strickPriceList.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    },  
    async initialFunctions(){
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
    }
  },
  computed: {
      ...mapState('symbols',['symbolsList','expiryList','currentExpiryDate']),
      expirydate: {
        get:function() {return this.currentExpiryDate},
        set:function(val) {}
      },
    },
  watch: {

  },

  mounted() {
      var isDark = localStorage.getItem("dark_theme");
      var theme = (isDark == 'true')?'dark':'light';
      // this.option = Object.assign(this.global.chart.defaultTheme[theme],this.option);
       this.initialFunctions()
  },

};
</script>
